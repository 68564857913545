import * as React from 'react';
import ArrowUpIcon from '!svg-react-loader!../images/vectors/arrow_up.svg';
import SEO from '../components/seo';
import './index.scss';
import { motion } from 'framer-motion';

const IndexPage = () => {
  return (
    <div>
      <SEO title="Home" />
      <div className="container-fluid main-container home-page-container">
        <div className='site-banner'>
          Smart products for doctors and healthcare service providers to
          collaborate and provide single-window experience to patients.
        </div>
      </div>
      <div className='home-page-footer page-footer-fixed'>
        <motion.div
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ repeat: Infinity, repeatDelay: 2, duration: 0.4 }}
        >
          <ArrowUpIcon />
        </motion.div>
        <span className={`page-footer-text`}>swipe up for products</span>
      </div>
    </div>
  );
};

export default IndexPage;
